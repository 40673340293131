if (document.referrer.indexOf("springboardperformance") === -1 && document.location.hostname.indexOf("localhost") === -1) {
    document.body.innerHTML = "";
    throw Error("Unallowed host");
}

const DATA = [
    {
        id: 1,
        title: "CENTRAL MEMORIAL PARK",
        location: "1221–2 St SW",
        artist: "Roubert Dilidili",
        song: "Uyemi - Where Have You Been",
        category: "Runway",
        vimeoLink: `https://player.vimeo.com/video/642033659`,
        description: `Central Memorial Park was a gay male cruising area in the 60s, 70s, and 80s, as well
as a site of police harassment of gay men, who could be incarcerated overnight with no
provocation. The Boer War memorial, in the middle of the park, was the site of the first
Pride Rally on Monday, June 18th, 1990, which Calgary Pride considers its origin event.
The park was sometimes a site of violent confrontation, including on one September
evening in 1979 when a gay basher was killed in a skirmish by those he had been
harassing, as reported in the city’s dailies, and the Body Politic.
`
    },
    {
        id: 2,
        title: "PARKSIDE CONTINENTAL",
        vimeoLink: `https://player.vimeo.com/video/642034398`,
        artist: "Bohlale Bam",
        song: "JHNN - Children Are the Future",
        category: "Runway",
        location: "1302–4 St SW",
        description: `The Parkside Continental, which ran from 1973–1986, was one of the first privately owned
gay bars in Calgary, named after a famous gay tavern in Toronto. Vance Campbell, a
businessman and gay bar owner from Vancouver, started the Parkside with local partners.
Campbell proved to be a divisive figure, at times publicly opposing the local gay activist
community. The Parkside expanded upstairs creating a second bar called The Green Room.
The Imperial Sovereign Court of the Chinook Arch was founded there in April 1976; their
first coronation followed in January 1977 at the Holiday Inn Downtown
`
    },
    {
        id: 3,
        title: "THE FRUIT LOOP",
        location: "13 Ave & 6 St SW",
        description: `The Gay Prostitution Stroll, colloquially known as “the Fruit Loop,” was for many years
centred around the Lougheed House. In 1982 local residents petitioned to have the stroll
removed. The Calgary Herald reported on June 21st, 1982 that police stepped up
enforcement efforts. Inspector Frank Mitchell noted enforcement difficulties, saying “if
there are five men walking down the street, two may be homosexual, one may be a
homosexual prostitute and two may be going to the library. It’s very difficult to assess.”
Is it a coincidence the stroll was across from the Ranchmen’s Club, one of Calgary’s last
remaining men-only private clubs?
`
    },
    {
        id: 4,
        title: "THE PANSY CLUB",
        location: "608–17 Ave SW",
        description: `Audrey Dwornik wrote the first thesis about homosexuality at the U of C in 1970, finding
that gay clubs in Calgary date to as early as 1939: “It was in this year that a homosexual
was arrested and he told the police about a homosexual club he belonged to. The name of
the club was the Pansy Club. The members of this club rented an apartment where no one
lived, but where they had their parties in private away from the fears of being arrested. The
club mainly had a social function, but it also acted as a means of self-protection for its
members while participating in homosexual relationships.” The apartment was likely in the
Beltline, in a historic walk-up.
`
    },
    {
        id: 5,
        title: "YES WE HAVE NO BANANAS/MADDISON’S",
        location: "320–17 Ave SW",
        description: `There were a number of short-lived gay bars here throughout the 80s and 90s. Two notable
venues from the early 90s were Yes We Have No Bananas, a lesbian bar known for wild
parties, including a legendary New Year’s Eve event; and Maddison’s, which was more of
a community lounge, hosting Imperial Court shows and male strippers on occasion. Bars
were central gathering points for the gay community before the Internet.
`
    },
    {
        id: 6,
        title: "VICTORIA’S",
        location: "318–17 Ave SW",
        description: `This address has been central to the gay community since the 1980s. The western side of
the building hosted a popular nightclub/disco initially called Dick’s, renamed 318, and
then Detours. The eastern side contained Victoria’s, the go-to gay restaurant in the 80s
and 90s, which featured a sizeable portrait of the queen. Behind Victoria’s, a second
short-lived dance floor was opened called the Empire Club. Finally, the Texas Lounge
and Goliath’s can be accessed from the building’s rear parking lot. A gay bathhouse
has been in this location since the 70s and is the only one currently remaining. Calgary
had three in the early 80s.
`
    },
    {
        id: 7,
        title: "GAY BASHING",
        location: "13 Ave & 1 St SW",
        description: `In the 1980s and 1990s, there was a high incidence of violence towards queers, and in
particular, gay men. Young men with pick-up trucks and baseball bats hunted around the
bars. Fuelled by a society dealing with AIDS anxieties, Calgary Police noted that few victims
reported these hate crimes. Nurse Jeff Harris, aged 38, was bashed here in the evening of
June 12, 1987. He lived, barely, after 6 hours of surgery: 280 stitches were needed to close
Harris’s wounds and wire together his 27 facial fractures, including 11 breaks in his jaw. He
recounted the harrowing, life-changing experience to the Calgary Herald in 1990. His three
assailants were found and charged, and tied to other gay-bashings in the neighbourhood.
The accused pled guilty and expressed surprise that Harris lived; the oldest attacker was 22.
`
    },
    {
        id: 8,
        title: "OLD Y",
        location: "223–12 Ave SW",
        description: `The historic community centre of the gay community and home to many LGBTQ2
organizations from the 1970s until today. The first community support organization was
the short-lived People’s Liberation Coalition in 1973 and then Gay Information and
Resources Calgary (GIRC) in 1975, the predecessor of Calgary Outlink, which is still
active today. A partial list of other groups connected to the Old Y includes: Gaylines,
Lesbian Information Lines, Lesbian and Gay Youth Calgary, Lesbian Mothers’ Defense
Fund, P-FLAG, Calgary Pride, and Fairy Tales.
`
    },
    {
        id: 9,
        title: "OFF CENTRE/BOXX CAFÉ/MONEYPENNIES",
        location: "119–15 Ave SW",
        description: `This was an eatery and bar, predominantly managed by women, which supported a
mixed clientele through the late 80s and 90s. Although it changed its name and ownership several times, it was open seven days a week and was a favourite brunch location
on weekends. Off Centre’s slogans included “a place for everyone” and “a place to
meet friends you haven’t seen since last night.” AIDS Calgary was also located on the
2nd floor of this building for many years around the turn of the century.
`
    },
    {
        id: 10,
        title: "A WOMAN’S PLACE BOOKSTORE",
        location: "1412 Centre St S",
        description: `Social worker Carolyn Anderson started what became a feminist community hub in the
early 80s after a trip to California where she discovered a lesbian bookshop that inspired
her. The lesbian section was located at the back of the store. Many customers who were
too nervous to go to gay bars found a gentler entry point to the community through
shopping for books and reading the community bulletin board. Canada Customs officials
seized books with gay or lesbian content in those days, but Carolyn would have her
shipments sent to friends in Montana, and then drive down to get them, smuggling the
books into Calgary.
`
    },
    {
        id: 1,
        title: "CLUB CAROUSEL",
        location: "1207–1 St SW (Basement)",
        description: `Calgary’s first gay owned and operated club began in 1970. When it opened, Calgary
Police charged the Club founders for operating a cabaret without a license. A sympathetic
lawyer, Harvey Ghitter, helped them become a private members club and charity. The
Club proved incredibly popular in the early 70s and inspired similar clubs in other prairie
cities. In the early 90s another gay bar, BBX (Bubblz Lounge, Balz Room, and XTZ Dance
Club), occupied all three levels of the building. The same basement space was a folk music
club called The Depression in the early 60s. In 1963 Joni Mitchell gave her first public
performance there.
`
    },
    {
        id: 12,
        title: "KINGS ARMS TAVERN",
        location: "1 St SW underpass at 9 Ave, east side.",
        description: `The Palliser Hotel, built in 1914, had a colourful watering hole called the Carriage House,
renamed the Kings Arms Tavern in 1970. The gay community called it “The Pit.” The Pit was a
known drinking establishment for gay men back to the 1950’s.  It was not an exclusively gay
venue, however, and was a popular spot for the business lunch crowd, old-timers in the afternoon and gays in the evening. Described as an old-fashioned beer parlour, through the 70s
the Pit developed an increasingly gay reputation. After a bar management change, the Kings
Arms attempted to change its clientele. Suspected gay patrons were denied service due to
clothing regulations, same-sex kissing or sitting too close together.  In December 1978, tensions
erupted between gay patrons and the tavern manager. After a heated verbal
exchange, there was a dramatic eviction of 20 customers from the bar, facilitated by six police
officers and four paddy wagons. The Kings Arms Tavern closed its doors on July 31, 1982
`
    },
    {
        id: 13,
        title: "CECIL HOTEL",
        vimeoLink: `https://player.vimeo.com/video/642033500`,
        artist: "Abhi Sahni",
        song: "Cartel Madras - WORKING",
        category: "Face",
        location: "401–4 Ave SE",
        description: `Sports, and specifically softball, provided an essential outlet for the development of lesbian
subculture. Calgary in the 60s had two women’s teams that were predominantly lesbian.
After a game, teammates would go to their favourite watering hole, which for many was
the backroom of the Cecil Hotel. One patron remembers: “We stumbled upon it by accident
— all these women — it was like finding mecca.” The Cecil Hotel had a notorious past as a
saloon and boarding house and was always described as rough. Built in 1912, the building
became a locus for prostitution, drugs, and murder. The City eventually revoked its business
license in 2009, and the building was demolished in 2015.
`
    },
    {
        id: 14,
        title: "HUDSON’S BAY WASHROOM",
        location: "200–8 Ave SW",
        description: `After World War II, there was for decades an ongoing domestic battle over public sex in
department store washrooms. In August 1963, Hudson’s Bay employee Clarence John
Young was charged with an act of indecency following police surveillance of the washroom
with closed-circuit television. Young later filed a lawsuit against both the Bay and Calgary
Police, “due to loss of employment, invasion of privacy, humiliation, and defamation by
being falsely characterized as homosexual or sexual deviate.” Unlike Young, most men
charged with gross indecency did not fight back. The public outing and ostracization of
being a found-in was life-altering and, for some, ended in suicide.
`
    },
    {
        id: 15,
        title: "GAY INFORMATION & RESOURCES CALGARY (GIRC)",
        vimeoLink: `https://player.vimeo.com/video/642034124`,
        artist: "Rocky Aquino",
        song: "JHNN - Children Are the Future",
        category: "Runway",
        location: "112–8 Ave SE",
        description: `Gay Information and Resources Calgary (GIRC) began in June 1975, spearheaded by gay
artist and activist Windi Earthworm. Although it moved to the Old Y in August 1976, its first
location was at Windi’s apartment in the historic Thomson Brothers Block on Stephen Avenue,
now part of the Hyatt Hotel complex. Windi and his cofounders were considered radicals:
upsetting the homophobic and uptight status quo. GIRC targeted local gays with a simple
newspaper ad consisting of their name and address, which the Calgary Herald refused to
print.  The newspaper’s advertising manager said GIRC’s goal was to destroy the family,
adding: “The Herald is a family medium and it’s going to stay that way.” GIRC complained
to the Alberta Human Rights Commission and the Alberta Press Council; both refused to
help. Fortunately, Calgary’s other daily, the Albertan, ran the ad.
`
    },
    {
        id: 16,
        title: "THE FIRE I’VE BECOME FILM FESTIVAL",
        vimeoLink: `https://player.vimeo.com/video/642034167`,
        artist: "Jared Herring",
        song: "DJ Dine & Dash - Skrappy's Crush",
        category: "Runway",
        location: "130–9 Ave SE",
        description: `In the early 1990s, the Of Colour Collective formed to do anti-racism work within Calgary’s
queer community. In June 1995, they organized a queer film festival titled The Fire I’ve
Become. The racy titles and perceived X-rated content of the program quickly attracted
the ire of social conservatives. The Glenbow Museum, the festival’s venue, got hundreds
of protesting calls and had reservations about letting it continue. After an intense meeting
with the Of Colour Collective and their supporters, Glenbow executives allowed it to
proceed. The four-day festival turned out to be very popular. Its audience members crossed
through a picket of demonstrators, but they filled the theatre every night.
`
    },
    {
        id: 17,
        title: "ANGELS IN AMERICA",
        vimeoLink: `https://player.vimeo.com/video/642034699`,
        artist: "Kaew McKinnon",
        song: "Evan Freeman - Without Your Love",
        category: "Face",
        location: "220–9 Ave SE",
        description: `On September 19, 1996, Alberta Theatre Projects (ATP) premiered Tony Kushner’s Pulitzer
Prize-winning play, Angels in America. Before opening, the play attracted controversy: “Why
are taxpayers still having to hand over hundreds of thousands of dollars to a company that
stages a self-indulgent production many feel is abhorrent? It is simply not right,” wrote the
Calgary Sun. A handful of MLAs were also on record questioning provincial funding of ATP.
Calgary Tory MLA Jon Havelock suggested that the play’s offense to community standards
should not receive public funding. He added, “It seems to me that in some instances people
confuse sexual expression with artistic expression.” The Calgary Herald’s Don Martin
managed to get Havelock to see the play with him. Surprisingly, the MLA loved it. He wrote,
“thoroughly enjoyable” on a comment card before he left. Ultimately, the controversy put
extra bums in seats. The play doubled expected ticket revenues and was sold out in its final
weeks — setting an audience record for the company.
`
    },
    {
        id: 18,
        title: "CITY HALL",
        vimeoLink: `https://player.vimeo.com/video/642033861`,
        artist: "Shandie Ta",
        song: "Selci - A Soft Place",
        category: "Performance",
        location: "800 Macleod Trail SE",
        description: `Calgary’s first pride parade began with a rally at the Municipal Plaza in June 1991,
organized by the Calgary Lesbian and Gay Political Action Guild (CLAGPAG). 400 people
came out to cheer gay Member of Parliament member Svend Robinson, who gave an inspiring speech despite gloomy weather and even gloomier protesters, three of whom were
arrested. CLAGPAG’s lobbying led to Mayor Al Duerr famously proclaimed the week of
June 16–23, 1991, “Gay Rights Week” in Calgary. The proclamation generated a firestorm
of protest amongst City Aldermen and the public at large. Duerr publicly regretted the
proclamation and resolved never to do it again, saying it was “without question, the most
difficult time I have ever gone through. The tremendous amount of hate directed at me
personally [was] very unfortunate. For that I am sorry.”
`
    },
    {
        id: 19,
        title: "CALGARY EAGLE",
        location: "424a–8 Ave SE",
        description: `In January 2002, the Calgary Eagle opened as a Calgary’s first leather/denim bar. Located
in the East Village’s historic Max Block, it housed various fetish communities as well as many
who preferred a laid-back drinking establishment. Their popular themed evenings included:
Kilt Night, Chaps Night, Harness Night and Military Uniform Night. Different social groups
coalesced around the Calgary Eagle, such as Bearback Calgary (for Bears and their admirers), and the Western Leather Federation. The bar remained successful but closed September
2012, due to East Village redevelopment. One of the owners in Gay Calgary Magazine
said: “This is going to be a high-end neighbourhood; they don’t want a gay leather bar.”
The building was torn down shortly after that.
`
    },
    {
        id: 20,
        title: "MYRTS/BACKLOT",
        location: "808–9 Ave SW & 815–7 St SW",
        description: `Opened in 1976, the sign on the building said Myrt’s Beauty Parlour. The gay lounge and
disco was initially open Friday and Saturday nights for men only. As its popularity grew, it
operated six nights a week and became a mixed club, reputed to play the best music in the
city. A hallway behind the dance floor led to a 150-seat theatre known as the “Backlot,”
which also served as an after-hours bar. The gay community was encouraged to use it as
much as possible; it was the venue for emerging theatre artists, Imperial Court drag shows,
Mr. Butch Calgary “Slave Auctions” and, on Sunday mornings, Metropolitan Community
Church services. Myrts’ final song every night was Kermit the Frog’s “Rainbow Connection.”
The bar closed on New Year’s Eve 1981, as the building fell victim to boomtown redevelopment. Myrts and the Backlot briefly moved to 17th avenue before it closed again. One former
patron broke into the site and retrieved the neon “Backlot” sign. The preserved sign now
hangs over the door of the contemporary Backlot bar on 10th Ave. SW.
`
    },
    {
        id: 21,
        title: "SUPREME COURT OF ALBERTA",
        vimeoLink: `https://player.vimeo.com/video/642034477`,
        artist: "Roubert Dilidili",
        song: "Sargeant X Comrade - Soul Queen (feat. Touch)",
        category: "Face",
        location: "530–7 Ave SW",
        description: `In 1960, a court case at the Supreme Court of Alberta (now Court of Queen’s Bench)
would begin Calgary bus driver Everett Klippert’s decade long trouble with the state.
Klippert had come under police scrutiny, due a complaint by the father of a young man
whom he had had sex with. On March 21st, Klippert was remanded into custody on a
charge of “contributing to the delinquency of a young boy.” Bail was set at $500. The
next day, March 22nd, his bail was increased to $9000, as the crown brought 17 more
charges forward due to the discovery of Klippert’s little black book, a document of his
dating life. The Albertan newspaper wrote: “Klippert faced the court with bowed head
Tuesday, elbows on the dock rail and face hidden in his hands, as the magistrate read the
17 new charges.” For the trial on May 2nd and May 4th Klippert pled guilty. Justice Hugh
Farthing described the case as “a particularly painful and distressing matter,” and
sentenced Klippert to his first jail term of 4 years. Klippert’s subsequent court cases
eventually led to Parliament’s decriminalization of homosexuality in 1969.
`
    },
    {
        id: 22,
        title: "620 CLUB",
        location: "620–8 Ave SW",
        description: `In 1968, a venue called the 620 Club opened near the site of the old Uptown Theatre.
Frequented by both gay men and lesbians, you accessed this underground bar by stairs in
the back alley between 5th and 6th streets. 620 was just a number on the door, and there
was no alcohol served, only popcorn and pop machines. The room was small, decorated by
strings of Christmas tree lights and a central light bulb hanging from the ceiling. A former
patron said: “One night I remember that it was really crowded and there were these three
guys in suits — one of whom I found quite attractive — so I went up to him and asked him to
dance, and he responded gruffly, “No!’ When I went back to my friends, they asked me
what I had done, and they said to me, ‘they are the police stupid, the light is flashing!’”
Before homosexuality was decriminalized in 1969 any same-sex couple dancing together
was potentially subject to arrest under the charge of gross indecency. The 620 Club used
the flashing light to alert its clientele to any suspected police presence, and dancing would
either cease or gay men and lesbians would switch partners and grab each other to dance.
`
    },
    {
        id: 23,
        title: "CLAGPAG ORIGINS",
        vimeoLink: `https://player.vimeo.com/video/642033547`,
        artist: "Sarah Alonso",
        song: "Freak Motif - Hot Plate",
        category: "Face",
        location: "209–4 Ave SE",
        description: `The catalyst for the formation of the Calgary Lesbian and Gay Political Action Guild
(CLAGPAG) came out of an act of discrimination. In the autumn of 1988, Project Pride
arranged a fundraising event to support a contingent to the 1990 Gay Games in Vancouver.
The Delta Bow Valley Hotel happily signed a contract with Project Pride for a banquet with 70
people. Upon learning who Project Pride represented, the hotel claimed that there had been
a “misunderstanding” and “the Delta was not prepared to rent to such a group.” Dr. Ruth
Simkin, an outspoken lesbian physician, and John Steen, a gay Liberal Party Organizer, formed
CLAGPAG and sent protest letters to the Calgary press, several politicians and the Delta Hotel’s
head office in Toronto. A flurry of excited local and national media coverage followed. The
president of Delta Hotels made a swift personal apology. He oversaw a cash donation to
CLAGPAG, which was seed money for the organization.
`
    },
    {
        id: 24,
        title: "MAC HALL",
        vimeoLink: `https://player.vimeo.com/video/642034267`,
        artist: "Roubert Dilidili",
        song: "Cartel Madras - WORKING",
        category: "Performance",
        location: "Old MacEwan Hall Ballroom, UCalgary",
        description: `On Tuesday, February 11th, 1969 more than 300 staff and students at the U of C attended
a lecture in the MacEwan Hall Ballroom by Harold Call, gay publisher and activist. He was
speaking at a University of Calgary Civil Liberties Association session billed as
“Homosexuality: A police industry.” Call, a founding member of the San Francisco chapter
of the Mattachine Society, edited the Mattachine Review, one of the earliest periodicals
dedicated to gay issues. Three city detectives sat quietly three rows from the front. Call spotted the police and invited them to comment. They did not move and left soon after.
`
    },
    {
        id: 25,
        title: "BLUE JEANS DAY, GLASS OFFICE",
        location: "McKimmie Library, UCalgary",
        description: `In 1991 and 1992 the University club GLASS (Gay, Lesbian and Bisexual Academics, Students
and Staff) held a clever and provocative event called “Blue Jeans Day,” to raise awareness of
gay rights. Campuses across North America marked National Coming Out Day on October
11th. The idea was to wear blue jeans if you supported gay rights — a controversial idea when
a large constituency of campus was already wearing denim. Blue Jeans Days were held at a
time when conflict over homophobia at U of C was intense. GLASS posters and advertising
were continually defaced. In February 1992, the Engineering Students’ Society was taken to
task by GLASS and subsequently censured by the University administration for homophobic
skits and antics during Engineering Week. In June 1992, an invitation to a “Fag & Lesbian
Bashing” was posted on the GLASS club office door. Calgary Police were called to investigate,
but no culprit was ever found.
`
    },
    {
        id: 26,
        title: "SCHOOL OF SOCIAL WELFARE",
        location: "Social Sciences Building, UCalgary",
        description: `The University of Calgary’s School of Social Welfare was the earliest academic explorer of
Calgary’s gay community. Grad student Audrey Dwornik’s 1970 Thesis, Self-Help and
Homosexuality, described discrimination and peer support in Calgary’s then gay community.
Self-described “Lesbian-Feminist” My Lipton appeared with gay activist Rick Sullivan as guest
speakers in a Human Sexuality course in late 1973, the first such course to be offered at the
University. The course was offered by Larry McKillop, who had studied at the Kinsey
Institute. Rick later completed his general studies thesis, Negotiating Minority Status: A Study
of Career Patterns Among Acculturated Gay Men, in 1974, with a Social Welfare faculty
advisor. Pioneering social work academic, Jim Gripton, joined the faculty in 1976. He was a
human sexuality expert, with progressive views on sexual and gender identity, who also
spearheaded the creation of the Faculty’s Ph.D. program.
`
    },
    {
        id: 27,
        title: "THE GAUNTLET",
        vimeoLink: `https://player.vimeo.com/video/642034591`,
        artist: "Abby Skaug",
        song: "Uyemi - WOW",
        category: "Performance",
        timestamp: "1:15 - 2:15",
        location: "319 MacEwan Hall, UCalgary",
        description: `The December 9, 1970, edition of the University of Calgary’s student newspaper the Gauntlet
had a classified ad that read: GAY IS GOOD — Anyone with questions about the gay scene or
desiring the friendship of other gay people write in confidence to Box 4031, Stn. C,
Calgary. The author was later revealed as “RAMONN.” In an April 1971 Gauntlet letter to the
editor, he wrote about the agony people had gone through in responding to his classified ad.
RAMONN’s identity remains unknown. In 1972 out and fiesty grad student, Rick Sullivan arrived
at U of C from Windsor, Ontario. He was instrumental in starting the first gay
organization on campus: the Gay Liberation Front which first met on September 29, 1972. Sullivan
shortly thereafter was invited to become a Gauntlet writer. He used its platform to both push gay
liberation in Calgary and to compassionately provide peer support to closeted students.
`
    },
    {
        id: 28,
        title: "CJSW — SPEAK SEBASTIAN",
        vimeoLink: `https://player.vimeo.com/video/642034037`,
        artist: "Abby Skaug",
        song: "Uyemi - WOW",
        category: "Performance",
        location: "MacEwan Hall, UCalgary",
        timestamp: "0:00 - 1:15",
        description: `CJSW 90.9 FM has been a beacon of alternative and independent voices on Calgary
airwaves since its inception. Its first queer show was Speak Sebastian. It premiered on
September 5, 1990. Niall O’Rourke, the show’s founder, said then “the gay community is
now confident enough to stand up and be counted.” The program was set up like many radio
lifestyle shows, with regular features and panels discussions. Speak Sebastian expanded in
1991 to produce a live community awards show. Michelle Sharp, Stephen Lock, and Cate
Vail were other notable hosts of Speak Sebastian. In 1994 Speak Sebastian volunteers Gene
Rodman and Craig Lewington created an alternative queer show, Freedom FM. In 1997, a
group of four enterprising lesbians launched Dykes on Mikes adding a third queer show to
CJSW programming. The original collective consisted of Michelle Wong, Vicki LaLonde,
Corinne Cornish and Kam Wong. CJSW’s broadcasts reached deeply into rural Alberta,
consoling many who feared to live openly.
`
    },
    {
        id: 29,
        title: "MAC HALL",
        vimeoLink: `https://player.vimeo.com/video/642034267`,
        artist: "Roubert Dilidili",
        song: "Cartel Madras - WORKING",
        category: "Performance",
        location: "Old MacEwan Hall Ballroom, UCalgary",
        description: `Sir John Wolfenden, Vice-Chancellor of Reading University in the United Kingdom, came
to Calgary in April 1973 as part of the University of Calgary’s distinguished lecture series.
He had been appointed to head an inquiry into British homosexuality in August 1954 after
a high profile gay scandal there. The inquiry unexpectedly recommended that:
“homosexual behaviour between consenting adults in private should no longer be a criminal offence.” Wolfenden’s final report, published in September 1957, sent ripples throughout the Commonwealth. His Calgary lecture, delivered in MacEwan Hall, was titled, ”Crime
and Sin: The Distinctions drawn in the Wolfenden Report.” He told the audience “there
are areas of private life that are outside the law.”
`
    },
    {
        id: 30,
        title: "MAC HALL",
        vimeoLink: `https://player.vimeo.com/video/642034267`,
        artist: "Roubert Dilidili",
        song: "Cartel Madras - WORKING",
        category: "Performance",
        location: "MacEwan Hall, UCalgary",
        description: `GAU groups started forming in the mid-70s in Central Canada. Calgary’s chapter was
established in 1977, as a “contact point for gays on campus, offering support and an
opportunity to share problems and information.” GAU organized an ongoing series of talks
that expanded the debate on gay, lesbian and transgender issues on campus. Some subjects
tackled were: gay life in Calgary, ‘parents and gays,’ gays and religion, sexual identity, ‘the
politics of deviance,’ and coming out (“The Closet Door is Heavy”). GAU played a critical
role in rallying the gay and lesbian community on campus (students as well as staff and
faculty), as well as providing peer support and coaxing people out of the closet. GAU
changed their name to Gay & Lesbian Academic Students and Staff (GLASS) in the 80s. Their
current incarnation is Queers on Campus.
`
    },
]

console.log(DATA.filter(x => x.vimeoLink).map(x => x.id));
function debounce(func, timeout = 300){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }
  
const svgRoot = document.querySelector('#queer-map-root');

const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)
}

const popup = document.querySelector('.queer-map-popup');

const closePopup = () => {
    if (popup && popup.classList.contains('open')) { 
        popup.classList.remove('open');
        popup.innerHTML = '';
    }
}

const createZoomRoot = (target) => {
    const root = svgPanZoom(target, {
        zoomEnabled: true,
        controlIconsEnabled: true,
        center: false,
        zoomScaleSensitivity: 0.2,
        contain: false,
        fit: true,
        minZoom: 2,
        maxZoom: isMobile() ? 12 : 6
    });

    root.zoom(isMobile() ? 6 : 3);
    root.center();
    return root;
}

function renderAttribute(key, value) {
    return `
    <div class="queer-map-attribute">
        <div class="queer-map-key">${key}</div>
        <div class="queer-map-value">${value}</div>
    </div>
    `
}

function renderDisplayBoxContentForId(id, x, y) {
    const item = DATA.find(x => x.id == id);
    let styles = `left: ${x}px; top: ${y}px`;
    if (isMobile()) {
        styles = ``;
    }
    
    return `
    <div class="queer-map-popup-inner" style="${styles}">
        <div class="queer-map-popup-close">
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        </div>
        ${item.vimeoLink ? `<div class="queer-map-video">
            <div style="padding:56.25% 0 0 0;position:relative;">
                <iframe 
                    src="${item.vimeoLink}?h=f5839cfb13&title=0&byline=0&portrait=0" 
                    style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                    frameborder="0" 
                    allow="autoplay; fullscreen; picture-in-picture" 
                    allowfullscreen
                >
                </iframe>
            </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
        </div>` : `<div class="queer-map-placeholder">
            <img src="/Placeholder.png" />
        </div>
        `}
        <div class="queer-map-title">
            <div class="queer-map-title-number">${item.id}</div>
            <div class="queer-map-title-text">
                ${item.title}
            </div>
        </div>
        <div class="queer-map-address">${item.location}</div>
        ${item.artist ? renderAttribute("Artist:", item.artist) : ""}
        ${item.song ? renderAttribute("Song:", item.song) : ""}
        ${item.category ? renderAttribute("Category:", item.category) : ""}
        <div class="queer-map-description">
            ${item.description}
        </div>
    </div>
    `
}

function calculatePopupLocation(element) {
    let x, y = 0;
    const vw = window.innerWidth || 0;
    const vh = window.innerHeight || 0;
    const MODAL_WIDTH = 500;
    const MODAL_HEIGHT = 700;
    let e_box = element.getBoundingClientRect();

    if (e_box.x + MODAL_WIDTH + 30 >= vw) {
        x = e_box.x - ((e_box.x + MODAL_WIDTH) - vw) - 30;
    } else {
        x = e_box.x;
    }

    if (e_box.y + MODAL_HEIGHT + 30 >= vh) {
        y = e_box.y - ((e_box.y + MODAL_HEIGHT) - vh) - 30;
    } else {
        y = e_box.y;
    }

    return {
        x,
        y
    }
}

function zoomAndDisplayBox(element, id) {

    const boxPoint = calculatePopupLocation(element);
    const contents = renderDisplayBoxContentForId(id, boxPoint.x, boxPoint.y);
    
    popup.innerHTML = contents;
    popup.classList.add('open');
    // Attach event listener for close
    const closeButton = document.querySelector('.queer-map-popup-close');
    closeButton.addEventListener('click', closePopup);
    closeButton.addEventListener('touchend', closePopup);
}

const _recursivelyFindParentUntilDataName = (element, id, count = 0) => {
    const value = element.getAttribute('data-name')
    if (value == id) {
        return element;
    }
    if ('parentNode' in element && element.parentNode) {
        return _recursivelyFindParentUntilDataName(element.parentNode, id, count+1);
    }
    return null;
}
const onPlaceClick = (id) => (event) => {
    const element = _recursivelyFindParentUntilDataName(event.srcElement, `Location ${id}`);

    if (element) {
        zoomAndDisplayBox(event.srcElement.parentNode, id);
    }
    
};
function initialize(src){
    const embed = document.createElement('embed');
    embed.setAttribute('style', 'width: 100%; height: 100%;');
    embed.setAttribute('type', 'image/svg+xml');
    embed.setAttribute('src', src);

    svgRoot.appendChild(embed);
    embed.addEventListener('load', function () { 
        zoomRoot = createZoomRoot(embed);
        // Set up event listeners for click
        for (let i = 0; i < DATA.length; i++) {
            const tagName = "Tab " + DATA[i].id;
            DATA[i].clickableElement = embed.getSVGDocument().querySelector(`[data-name="${tagName}"]`);
            if (DATA[i].clickableElement) {
                DATA[i].clickableElement.addEventListener('click', onPlaceClick(DATA[i].id));
                DATA[i].clickableElement.addEventListener('touchend', onPlaceClick(DATA[i].id));
            }
        }
        window.addEventListener('resize', debounce(() => {
            console.log("Finished resizing!");
            zoomRoot.resize();
            zoomRoot.zoom(isMobile() ? 6 : 3);
            zoomRoot.center();
        }, 100))
        document.querySelector('.loader-container').remove();
        
    });

    return embed
}

initialize("/QueerMapV2.svg");